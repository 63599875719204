import { useMemo } from 'react'
import { useIsWindowVisible } from '@pulsex/hooks'
import { CurrencyAmount, TradeType, Currency } from '@pulsex/sdk'
import { useIsWrapping } from 'hooks/useWrapCallback'
import { useAutorouterTrade } from 'state/autorouter/hooks'
import { InterfaceTrade, TradeState } from 'state/autorouter/types'

export function useBestTrade(
  tradeType: TradeType,
  amount?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
} {
  const isWindowVisible = useIsWindowVisible()
  const isWrapping = useIsWrapping(amount?.currency, otherCurrency, amount?.toExact())
  const shouldGetTrade = !isWrapping && isWindowVisible

  const routingAPITrade = useAutorouterTrade(
    shouldGetTrade ? amount : undefined,
    otherCurrency,
    tradeType,
    true,
  )

  const isLoading = routingAPITrade.state === TradeState.LOADING

  // only return gas estimate from api if routing api trade is used
  return useMemo(
    () => ({
      ...(routingAPITrade),
      ...(isLoading ? { state: TradeState.LOADING } : {}),
    }),
    [isLoading, routingAPITrade]
  )
}
