import { ChainId } from '@pulsex/chains'

export const PLSX_EXTENDED = 'https://tokens.app.pulsex.com/pulsex-extended.tokenlist.json'

const PULSECHAIN_URLS = [PLSX_EXTENDED]

// List of official tokens list
export const OFFICIAL_LISTS = [PLSX_EXTENDED]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...PULSECHAIN_URLS,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  PLSX_EXTENDED
]

export const MULTI_CHAIN_LIST_URLS: { [chainId: number]: string[] } = {
  [ChainId.PULSECHAIN]: PULSECHAIN_URLS,
  [ChainId.PULSECHAIN_TESTNET]: [],
}
