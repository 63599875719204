import { ChainId } from '@pulsex/chains'
import { AlphaRouter } from '@pulsex/smart-order-router'
import { Protocol } from '@pulsex/smart-order-router/src/routers/sdk'
import { Currency, CurrencyAmount, TradeType } from '@pulsex/sdk'
import { useDebounce } from '@pulsex/hooks'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useDeferredValue, useMemo } from 'react'
import qs from 'qs'
import { QUOTING_API } from 'config/constants/endpoints'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useStablecoinAmountFromFiatValue } from 'hooks/useUSDCPrice'
import { useCurrentBlock } from 'state/block/hooks'
import { getViemClients } from 'utils/viem'
import { GetQuoteResult, InterfaceTrade, TradeState } from './types'
import { computeRoutes, getClientSideQuote, transformRoutesToTrade } from './utils'

// Revalidate interval in milliseconds
const REVALIDATE_AFTER = {
  [ChainId.PULSECHAIN]: 20_000,
  [ChainId.PULSECHAIN_TESTNET]: 20_000,
}
const CLIENT_PARAMS = {
  protocols: [Protocol.V1, Protocol.V2, Protocol.MIXED],
  minSplits: 1,
}
const QUERY_TIMEOUT = 20000 // cancel api query if it takes longer than 20sec
const QUERY_RETRY_COUNT = 3

async function fetchQuote(
  currencyIn: Currency,
  currencyOut: Currency,
  deferQuotient: string,
  tradeType: TradeType,
  router: AlphaRouter,
  retries: number = QUERY_RETRY_COUNT
) {
  try {
    const query = qs.stringify({
      tokenInAddress: currencyIn.wrapped.address,
      tokenOutAddress: currencyOut.wrapped.address,
      chainId: currencyIn.chainId,
      amountRaw: deferQuotient,
      type: tradeType === TradeType.EXACT_INPUT ? 'exactIn' : 'exactOut',
    })

    // Fetch with timeout
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), QUERY_TIMEOUT)
    const response = await fetch(`${QUOTING_API}?${query}`, {
      signal: controller.signal
    })

    // Clear timeout
    clearTimeout(timeoutId);

    // TODO: handle different types of errors appropriately
    if (!response.ok) {
      console.error('API fetch failed, trying client side routing')
      try {
        const { data } = await getClientSideQuote(
          deferQuotient,
          currencyIn,
          currencyOut,
          tradeType,
          router,
          CLIENT_PARAMS
        )
        return { ...data }
      } catch {
        console.error('Client side routing failed')
        return undefined
      }
    }
    const parsed = await response.json()
    return parsed
  } catch (e) {
    if (e instanceof DOMException && e.name === 'AbortError' && retries > 0) {
      console.error('Fetch aborted due to timeout, retrying...')
      // Retry fetch
      return fetchQuote(currencyIn, currencyOut, deferQuotient, tradeType, router, retries - 1)
    }
    console.error(`Failed to fetch quote: ${e}`)
    return undefined
  }
}

/**
 * @dev Returns the quote from the routing api or the client side router
 * @param key string of the query
 * @param amount the exact amount to swap in/out
 * @param currencyIn the desired input currency
 * @param currencyOut the desired output currency
 * @param tradeType whether the swap is an exact in/out
 * @param autoRevalidate
 */
function useQuoter(
  key: string,
  amount: CurrencyAmount<Currency> | undefined,
  currencyIn: Currency | undefined,
  currencyOut: Currency | undefined,
  tradeType: TradeType,
  autoRevalidate: boolean,
): {
  quote: GetQuoteResult
  isLoading: boolean
  isSyncing: boolean
  isStale: boolean
  isError: boolean
  error: Error
} {
  const { chainId } = useActiveWeb3React()
  const provider = getViemClients({ chainId })
  const blockNumber = useCurrentBlock()

  const deferredChainId = useDeferredValue(currencyIn?.chainId)
  const deferredIdentifierIn = useDeferredValue(currencyIn?.isToken ? currencyIn?.address : currencyIn?.symbol)
  const deferredIdentifierOut = useDeferredValue(currencyOut?.isToken ? currencyOut?.address : currencyOut?.symbol)
  const deferredTradeType = useDeferredValue(tradeType)
  const deferQuotientRaw = useDeferredValue(amount?.quotient.toString())
  const deferQuotient = useDebounce(deferQuotientRaw, 500)

  const router = new AlphaRouter({
    chainId,
    provider,
  })

  const {
    data: quote,
    fetchStatus,
    isPlaceholderData,
    isLoading,
    isPending,
    isError,
    error
  } = useQuery<GetQuoteResult, Error>({
    queryKey: [
      key,
      deferredChainId,
      deferredIdentifierIn,
      deferredIdentifierOut,
      deferredTradeType,
      deferQuotient
    ],
    queryFn: () => {
      if (!amount || !currencyIn || !currencyOut || !deferQuotient) {
        return undefined
      }
      return fetchQuote(currencyIn, currencyOut, deferQuotient, tradeType, router)
    },
    enabled: Boolean(deferredChainId && deferredIdentifierIn && deferredIdentifierOut && deferQuotient),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    retry: false,
    staleTime: autoRevalidate ? REVALIDATE_AFTER[currencyOut?.chainId] : 0,
    refetchInterval: autoRevalidate && REVALIDATE_AFTER[currencyOut?.chainId],
  })

  const isValidating = fetchStatus === 'fetching'
  const isSyncing = isValidating || (amount?.quotient.toString() !== deferQuotient && deferQuotient !== undefined)
  const isStale = quote?.blockNumber !== blockNumber.toString()

  return {
    quote,
    isLoading: isPending || isLoading || isPlaceholderData,
    isSyncing,
    isStale,
    isError,
    error,
  }
}

/**
 * @dev Returns the best trade by invoking the routing api or the client side router
 * @param amount the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 * @param tradeType whether the swap is an exact in/out
 * @param autoRevalidate
 */
export function useAutorouterTrade(
  amount: CurrencyAmount<Currency> | undefined,
  otherCurrency: Currency | undefined,
  tradeType: TradeType,
  autoRevalidate: boolean
): {
  state: TradeState
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
} {
  const [currencyIn, currencyOut]: [Currency | undefined, Currency | undefined] = useMemo(
    () =>
      tradeType === TradeType.EXACT_INPUT
        ? [amount?.currency, otherCurrency]
        : [otherCurrency, amount?.currency],
    [amount, otherCurrency, tradeType]
  )

  const {
    quote: quoteResult,
    isLoading,
    isSyncing,
    isError,
  } = useQuoter(
    "useAutorouterTrade",
    amount,
    currencyIn,
    currencyOut,
    tradeType,
    autoRevalidate,
  )

  const route = useMemo(
    () => computeRoutes(currencyIn, currencyOut, tradeType, quoteResult),
    [currencyIn, currencyOut, quoteResult, tradeType]
  )

  // get USD gas cost of trade in active chains stablecoin amount
  const gasUseEstimateUSD = useStablecoinAmountFromFiatValue(quoteResult?.gasUseEstimateUSD) ?? null
  return useMemo(() => {
    if (!currencyIn || !currencyOut || currencyIn.equals(currencyOut)) {
      return {
        state: TradeState.INVALID,
        trade: undefined,
      }
    }

    if (isLoading) {
      return {
        state: TradeState.LOADING,
        trade: undefined,
      }
    }

    let otherAmount: CurrencyAmount<Currency>
    if (quoteResult?.quote) {
      if (tradeType === TradeType.EXACT_INPUT && currencyOut) {
        otherAmount = CurrencyAmount.fromRawAmount(currencyOut, quoteResult.quote)
      }

      if (tradeType === TradeType.EXACT_OUTPUT && currencyIn) {
        otherAmount = CurrencyAmount.fromRawAmount(currencyIn, quoteResult.quote)
      }
    }

    if (isError || !otherAmount || !route || route.length === 0) {
      return {
        state: TradeState.NO_ROUTE_FOUND,
        trade: undefined,
      }
    }

    try {
      const trade = transformRoutesToTrade(route, tradeType, quoteResult?.blockNumber, gasUseEstimateUSD)
      return {
        // always return VALID regardless of isFetching status
        state: isSyncing ? TradeState.SYNCING : TradeState.VALID,
        trade,
      }
    } catch (e) {
      return { state: TradeState.INVALID, trade: undefined }
    }
  }, [
    currencyIn,
    currencyOut,
    quoteResult,
    isLoading,
    tradeType,
    isError,
    route,
    gasUseEstimateUSD,
    isSyncing,
  ])
}
