import { Currency, Token } from '@pulsex/sdk'
import { ChainId } from '@pulsex/chains'
import { Flex, Text } from '@pulsex/uikit'
import styled from 'styled-components'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { SUGGESTED_BASES } from '../../config/constants/exchange'
import { CurrencyLogo } from '../Logo'

const Wrapper = styled(Flex)`
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding-bottom: 10px;
`

const BaseWrapper = styled("div").withConfig({
  shouldForwardProp: (props) => !["disable"].includes(props),
})<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.dropdown)};
  border-radius: 10px;
  display: flex;
  padding: 3px;

  align-items: center;
  &:hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background: ${({ theme, disable }) => !disable && theme.colors.dropdown};
  }
  opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const native = useNativeCurrency()
  return (
    <Wrapper>
      <Flex justifyContent="flex-start" flexWrap="wrap">
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !selectedCurrency.isNative) {
              onSelect(native)
            }
          }}
          disable={selectedCurrency?.isNative}
          style={{ marginRight: '10px', marginBottom: '10px' }}
        >
          <CurrencyLogo currency={native} style={{ marginRight: 8 }} />
          <Text>PLS</Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected = selectedCurrency?.equals(token)
          return (
            <BaseWrapper onClick={() => !selected &&
              onSelect(token)}
              disable={selected}
              key={token.address}
              style={{ marginRight: '10px', marginBottom: '10px' }}
            >
              <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
              <Text>{token.symbol}</Text>
            </BaseWrapper>
          )
        })}
      </Flex>
    </Wrapper>
  )
}
