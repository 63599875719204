import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useMemo } from 'react'

import fetchPoolChartData from 'state/info/queries/pools/chartData'
import { fetchAllPoolData, fetchAllPoolDataWithAddress } from 'state/info/queries/pools/poolData'
import fetchPoolTransactions from 'state/info/queries/pools/transactions'
import { fetchGlobalChartData } from 'state/info/queries/protocol/chart'
import { fetchProtocolData } from 'state/info/queries/protocol/overview'
import fetchTopTransactions from 'state/info/queries/protocol/transactions'
import fetchTokenChartData from 'state/info/queries/tokens/chartData'
import fetchPoolsForToken from 'state/info/queries/tokens/poolsForToken'
import fetchTokenPriceData from 'state/info/queries/tokens/priceData'
import { fetchAllTokenData, fetchAllTokenDataByAddresses } from 'state/info/queries/tokens/tokenData'
import fetchTokenTransactions from 'state/info/queries/tokens/transactions'
import { Block, Transaction } from 'state/info/types'
import { useQuery } from '@tanstack/react-query'
import { getDeltaTimestamps } from 'utils/getDeltaTimestamps'
import { useBlockFromTimeStampQuery } from 'pages/Info/hooks/useBlocksFromTimestamps'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ChartEntry, PoolData, PriceChartEntry, ProtocolData, TokenData } from './types'

dayjs.extend(duration)

// Protocol hooks

export const refreshIntervalForInfo = 15000 // 15s
export const QUERY_SETTINGS_IMMUTABLE = {
  refetchOnReconnect: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
}
export const QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH = {
  retry: 3,
  retryDelay: 3000,
}
export const QUERY_SETTINGS_INTERVAL_REFETCH = {
  refetchInterval: refreshIntervalForInfo,
  keepPreviousData: true,
  ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
}

export const useProtocolDataQuery = (protocol: string): ProtocolData | undefined => {
  const { chainId } = useActiveChainId()
  const [t24, t48] = getDeltaTimestamps()
  const { blocks } = useBlockFromTimeStampQuery([t24, t48])
  const [block24, block48] = blocks ?? []
  const { data: protocolData } = useQuery({
    queryKey: [`info/${protocol}/protocol/updateProtocolData`, chainId],
    queryFn: () => fetchProtocolData(chainId, protocol, block24, block48),
    enabled: Boolean(chainId && block24 && block48),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })
  return protocolData ?? undefined
}

export const useProtocolChartDataQuery = (protocol: string): ChartEntry[] | undefined => {
  const { chainId } = useActiveChainId()
  const { data: chartData } = useQuery({
    queryKey: [`info/${protocol}/protocol/updateProtocolChartData`, chainId],
    queryFn: () => fetchGlobalChartData(chainId, protocol),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })
  return chartData ?? undefined
}

export const useProtocolTransactionsQuery = (protocol: string): Transaction[] | undefined => {
  const { chainId } = useActiveChainId()
  const { data: transactions } = useQuery({
    queryKey: [`info/${protocol}/protocol/updateProtocolTransactionsData`, chainId],
    queryFn: () => fetchTopTransactions(chainId, protocol),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_INTERVAL_REFETCH, // update latest Transactions per 15s
  })
  return transactions ?? undefined
}

// export const useFeeToMints = (): [
//   FeeToMintTransaction[] | undefined,
//   (transactions: FeeToMintTransaction[]) => void,
// ] => {
//   const feeToMints: FeeToMintTransaction[] | undefined = useSelector(
//     (state: AppState) => state.info.protocol.feeToMints,
//   )
//   const dispatch = useDispatch<AppDispatch>()
//   const setFeeToMints: (feeToMints: FeeToMintTransaction[]) => void = useCallback(
//     (transactionsData: FeeToMintTransaction[]) => dispatch(updateFeeToMints({ feeToMints: transactionsData })),
//     [dispatch],
//   )
//   return [feeToMints, setFeeToMints]
// }

// Pools hooks

export const useAllPoolDataQuery = (protocol: string) => {
  const { chainId } = useActiveChainId()
  const [t24h, t48h, t7d, t14d] = getDeltaTimestamps()
  const { blocks } = useBlockFromTimeStampQuery([t24h, t48h, t7d, t14d])
  const { data } = useQuery({
    queryKey: [`info/${protocol}/pools/data`, chainId],
    queryFn: () => fetchAllPoolData(chainId, protocol, blocks),
    enabled: Boolean(blocks && chainId),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })
  return useMemo(() => {
    return data ?? {}
  }, [data])
}

export const usePoolDatasQuery = (protocol: string, poolAddresses: string[]): PoolData[] => {
  const { chainId } = useActiveChainId()
  const name = poolAddresses.join('')
  const [t24h, t48h, t7d, t14d] = getDeltaTimestamps()
  const { blocks } = useBlockFromTimeStampQuery([t24h, t48h, t7d, t14d])
  const { data } = useQuery({
    queryKey: [`info/${protocol}/pool/data/${name}`, chainId],
    queryFn: () => fetchAllPoolDataWithAddress(chainId, protocol, blocks, poolAddresses),
    enabled: Boolean(blocks && chainId),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_INTERVAL_REFETCH,
  })

  return useMemo(() => {
    return poolAddresses
      .map((address) => {
        return data?.[address]?.data
      })
      .filter((pool) => pool)
  }, [data, poolAddresses])
}

export const usePoolChartDataQuery = (protocol: string, address: string): ChartEntry[] | undefined => {
  const { chainId } = useActiveChainId()
  const { data } = useQuery({
    queryKey: [`info/${protocol}/pool/chartData/${address}`, chainId],
    queryFn: () => fetchPoolChartData(chainId, protocol, address),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })
  return data?.data ?? undefined
}

export const usePoolTransactionsQuery = (protocol: string, address: string): Transaction[] | undefined => {
  const { chainId } = useActiveChainId()
  const { data } = useQuery({
    queryKey: [`info/${protocol}/pool/transactionsData/${address}`, chainId],
    queryFn: () => fetchPoolTransactions(chainId, protocol, address),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })
  return data?.data ?? undefined
}

// Tokens hooks

export const useAllTokenHighLight = (protocol: string): TokenData[] => {
  const { chainId } = useActiveChainId()
  const [t24h, t48h, t7d, t14d] = getDeltaTimestamps()
  const { blocks } = useBlockFromTimeStampQuery([t24h, t48h, t7d, t14d])
  const { data, isLoading } = useQuery({
    queryKey: [`info/${protocol}/token/data`, chainId],
    queryFn: () => fetchAllTokenData(chainId, protocol, blocks),
    enabled: Boolean(blocks && chainId),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })

  const tokensWithData = useMemo(() => {
    return data
      ? Object.keys(data)
        .map((k) => {
          return data?.[k]?.data
        })
        .filter((d) => d && d.exists)
      : []
  }, [data])
  return useMemo(() => {
    return isLoading ? [] : tokensWithData ?? []
  }, [isLoading, tokensWithData])
}

export const useAllTokenDataQuery = (protocol: string): {
  [address: string]: { data?: TokenData }
} => {
  const { chainId } = useActiveChainId()
  const [t24h, t48h, t7d, t14d] = getDeltaTimestamps()
  const { blocks } = useBlockFromTimeStampQuery([t24h, t48h, t7d, t14d])
  const { data } = useQuery({
    queryKey: [`info/${protocol}/token/data`, chainId],
    queryFn: () => fetchAllTokenData(chainId, protocol, blocks),
    enabled: Boolean(blocks && chainId),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })
  return data ?? {}
}

const graphPerPage = 50

const fetcher = (chainId: number, protocol: string, addresses: string[], blocks: Block[]) => {
  const times = Math.ceil(addresses.length / graphPerPage)
  const addressGroup = []
  for (let i = 0; i < times; i++) {
    addressGroup.push(addresses.slice(i * graphPerPage, (i + 1) * graphPerPage))
  }
  return Promise.all(addressGroup.map((d) => fetchAllTokenDataByAddresses(chainId, protocol, blocks, d)))
}

export const useTokenDatasQuery = (protocol: string, addresses?: string[], withSettings = true): TokenData[] | undefined => {
  const { chainId } = useActiveChainId()
  const name = addresses.join('')
  const [t24h, t48h, t7d, t14d] = getDeltaTimestamps()
  const { blocks } = useBlockFromTimeStampQuery([t24h, t48h, t7d, t14d])
  const { data, isLoading } = useQuery({
    queryKey: [`info/${protocol}/token/data/${name}`, chainId],
    queryFn: () => fetcher(chainId, protocol, addresses, blocks),
    enabled: Boolean(blocks && chainId),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...(withSettings ? QUERY_SETTINGS_INTERVAL_REFETCH : QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH),
  })
  const allData = useMemo(() => {
    return data && data.length > 0
      ? data.reduce((a, b) => {
        return { ...a, ...b }
      }, {})
      : {}
  }, [data])

  const tokensWithData = useMemo(() => {
    if (!addresses && allData) {
      return undefined
    }
    return addresses
      .map((a) => {
        return allData?.[a]?.data
      })
      .filter((d) => d && d.exists)
  }, [addresses, allData])

  return useMemo(() => {
    return isLoading ? [] : tokensWithData ?? undefined
  }, [isLoading, tokensWithData])
}

export const useTokenDataQuery = (protocol: string, address: string | undefined): TokenData | undefined => {
  const allTokenData = useTokenDatasQuery(protocol, [address])
  return allTokenData.find((d) => d.address === address) ?? undefined
}

export const usePoolsForTokenQuery = (protocol: string, address: string): string[] | undefined => {
  const { chainId } = useActiveChainId()
  const { data } = useQuery({
    queryKey: [`info/${protocol}/token/poolAddress/${address}`, chainId],
    queryFn: () => fetchPoolsForToken(chainId, protocol, address),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_WITHOUT_INTERVAL_REFETCH,
  })

  return data?.addresses ?? undefined
}

export const useTokenChartDataQuery = (protocol: string, address: string): ChartEntry[] | undefined => {
  const { chainId } = useActiveChainId()
  const { data } = useQuery({
    queryKey: [`info/${protocol}/token/chartData/${address}`, chainId],
    queryFn: () => fetchTokenChartData(chainId, protocol, address),
    enabled: Boolean(address && chainId),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_INTERVAL_REFETCH,
  })

  return data?.data ?? undefined
}

export const useTokenPriceDataQuery = (
  protocol: string,
  address: string,
  interval: number,
  timeWindow: duration.Duration,
): PriceChartEntry[] | undefined => {
  const { chainId } = useActiveChainId()
  const startTimestamp = dayjs().subtract(timeWindow).startOf('hours').unix()
  const { data } = useQuery({
    queryKey: [`info/${protocol}/token/priceData/${address}`, chainId],
    queryFn: () => fetchTokenPriceData(chainId, protocol, address, interval, startTimestamp),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_INTERVAL_REFETCH,
  })
  return data?.data ?? undefined
}

export const useTokenTransactionsQuery = (protocol: string, address: string): Transaction[] | undefined => {
  const { chainId } = useActiveChainId()
  const { data } = useQuery({
    queryKey: [`info/${protocol}/token/transactionsData/${address}`, chainId],
    queryFn: () => fetchTokenTransactions(chainId, protocol, address),
    ...QUERY_SETTINGS_IMMUTABLE,
    ...QUERY_SETTINGS_INTERVAL_REFETCH,
  })
  return data?.data ?? undefined
}
