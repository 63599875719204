import { useMemo } from 'react'
import styled from 'styled-components'
import { Currency, TradeType } from '@pulsex/sdk'
import { Box, Text, ArrowDownIcon, Flex } from '@pulsex/uikit'
import { InterfaceTrade } from 'state/autorouter/types'
import { computeRealizedPriceImpact, warningSeverity } from 'utils/exchange'
import { CurrencyLogo } from 'components/Logo'
import { TruncatedText } from '../../components/styleds'

const StyledHeaderRow = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.input};
  padding: 0.5rem 0.5rem;
`
const ArrowWrapper = styled.div`
  border-radius: 50%;
  height: 28px;
  width: 28px;
  position: relative;
  margin-top: -11px;
  margin-bottom: -11px;
  left: calc(50% - 14px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tertiary};
  z-index: 2;
`

export default function SwapModalHeaderV2({
  trade,
  showAcceptChanges,
}: {
  trade: InterfaceTrade<Currency, Currency, TradeType>
  showAcceptChanges: boolean
}) {
  const { priceImpactWithoutFee } = useMemo(() => computeRealizedPriceImpact(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  return (
    <>
      <StyledHeaderRow>
        <TruncatedText
          fontSize="24px"
          color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? 'primary' : 'text'}
        >
          {trade.inputAmount.toSignificant(6)}
        </TruncatedText>
        <Flex alignContent="center">
          <CurrencyLogo currency={trade.inputAmount.currency} size="24px" />
          <Text fontSize="18px" bold ml="10px">
            {trade.inputAmount.currency.symbol}
          </Text>
        </Flex>
      </StyledHeaderRow>
      <ArrowWrapper>
        <ArrowDownIcon className="icon-down" color="primary" />
      </ArrowWrapper>
      <StyledHeaderRow>
        <TruncatedText
          fontSize="24px"
          color={
            priceImpactSeverity > 2
              ? 'failure'
              : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
              ? 'primary'
              : 'text'
          }
        >
          {trade.outputAmount.toSignificant(6)}
        </TruncatedText>

        <Flex alignContent="center">
          <CurrencyLogo currency={trade.outputAmount.currency} size="24px" />
          <Text fontSize="18px" bold ml="10px">
            {trade.outputAmount.currency.symbol}
          </Text>
        </Flex>
      </StyledHeaderRow>
    </>
  )
}
