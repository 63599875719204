import { styled } from 'styled-components'
import { Flex, Text, OpenNewIcon } from '@pulsex/uikit'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { PULSECHAIN_BRIDGE_URL } from 'config/constants/endpoints'

const StyledAnchor = styled.a`
  text-decoration: none; /* Remove underline from anchor */
`;

export const StyledBridgeButton = styled(Flex)`
  width: 360px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 50px;
  padding: 0;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundAlt2};
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 450px;
    padding: 0;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    width: 450px;
    padding: 0;
  }
`

export const BridgeButton = () => {
  const { chainId } = useActiveChainId()
  return (
    <StyledAnchor href={PULSECHAIN_BRIDGE_URL} target="_blank" rel="noopener noreferrer">
      <StyledBridgeButton mt="10px">
      <Flex p="10px">
        <ChainLogo chainId={chainId} />
        <Text pl="5px">PulseChain Bridge</Text>
      </Flex>
      <Flex p="10px">
        <OpenNewIcon />
      </Flex>
    </StyledBridgeButton>
    </StyledAnchor>
  )
}
