import { Currency, TradeType } from '@pulsex/sdk'
import { useMemo } from 'react'
import { InterfaceTrade } from 'state/autorouter/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { computeSmartRouterSlippageAdjustedAmounts } from 'utils/exchange'

export function useSlippageAdjustedAmounts(trade?: InterfaceTrade<Currency, Currency, TradeType>) {
  const [allowedSlippage] = useUserSlippageTolerance()
  return useMemo(() => computeSmartRouterSlippageAdjustedAmounts(trade, allowedSlippage), [allowedSlippage, trade])
}
