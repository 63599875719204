import { useCallback, useMemo } from 'react'
import { Currency, TradeType } from '@pulsex/sdk'
import { InjectedModalProps } from '@pulsex/uikit'
import { useTranslation } from '@pulsex/localization'
import { InterfaceTrade } from 'state/autorouter/types'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from 'components/TransactionConfirmationModal'
import SwapModalFooterV2 from './SwapModalFooterV2'
import SwapModalHeaderV2 from './SwapModalHeaderV2'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: InterfaceTrade<Currency, Currency, TradeType>, tradeB: InterfaceTrade<Currency, Currency, TradeType>): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  )
}
interface ConfirmSwapModalProps {
  trade?: InterfaceTrade<Currency, Currency, TradeType>
  originalTrade?: InterfaceTrade<Currency, Currency, TradeType>
  attemptingTxn: boolean
  txHash?: string
  recipient: string | null
  allowedSlippage: number
  isMobile?: boolean
  swapErrorMessage?: string
  onAcceptChanges: () => void
  onConfirm: () => void
  customOnDismiss?: () => void
}

const ConfirmSwapModalV2: React.FC<InjectedModalProps & ConfirmSwapModalProps> = ({
  trade,
  originalTrade,
  attemptingTxn,
  txHash,
  recipient,
  allowedSlippage,
  isMobile,
  swapErrorMessage,
  onAcceptChanges,
  onConfirm,
  customOnDismiss,
  onDismiss,
}) => {
  const showAcceptChanges = useMemo(
    () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
    [originalTrade, trade],
  )

  const { t } = useTranslation()

  const modalHeader = useCallback(() => {
    return trade ? (
      <SwapModalHeaderV2
        trade={trade}
        showAcceptChanges={showAcceptChanges}
      />
    ) : null
  }, [showAcceptChanges, trade])

  const modalBottom = useCallback(() => {
    return trade ? (
      <SwapModalFooterV2
        onConfirm={onConfirm}
        trade={trade}
        recipient={recipient}
        disabledConfirm={showAcceptChanges}
        swapErrorMessage={swapErrorMessage}
        allowedSlippage={allowedSlippage}
        showAcceptChanges={showAcceptChanges}
        isMobile={isMobile}
        onAcceptChanges={onAcceptChanges}
      />
    ) : null
  }, 
  [
    allowedSlippage, 
    onConfirm, 
    showAcceptChanges, 
    swapErrorMessage, 
    trade, 
    isMobile, 
    onAcceptChanges, 
    recipient
  ])

  // text to show while loading
  const pendingText = t('Swapping %amountA% %symbolA% for %amountB% %symbolB%', {
    amountA: trade?.inputAmount?.toSignificant(6) ?? '',
    symbolA: trade?.inputAmount?.currency?.symbol ?? '',
    amountB: trade?.outputAmount?.toSignificant(6) ?? '',
    symbolB: trade?.outputAmount?.currency?.symbol ?? '',
  })

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage],
  )

  return (
    <TransactionConfirmationModal
      title={t('Confirm Swap')}
      onDismiss={onDismiss}
      customOnDismiss={customOnDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={trade?.outputAmount.currency}
    />
  )
}

export default ConfirmSwapModalV2
